export function getControleDeAcesso(codigo, authUserInfo) {
  // console.log('authUserInfo', authUserInfo);

  if (authUserInfo != null) {
    // ACESSO DO NEGOCIADOR
    if (authUserInfo.perfil == 'NEGOCIADOR') {
      if (codigo == 'cad_devedor_v') return !authUserInfo.bloq_lista_devedor;
      if (codigo == 'fin_bol_imp') return true;
    }

    // configurações de empresa
    // acesso para o negociador poder filtrar credor na lista de devedores
    if (codigo == 'conf_neg_ativa_credor') {
      if (authUserInfo.perfil == 'NEGOCIADOR')
        return authUserInfo.conf_neg_ativa_credor;
      if (authUserInfo.perfil != 'NEGOCIADOR') return false;
    }

    // acesso para o negociador poder visualizar as obs do credor no processo
    if (codigo == 'conf_neg_inativa_obs_credor') {
      if (authUserInfo.perfil == 'NEGOCIADOR')
        return authUserInfo.conf_neg_inativa_obs_credor;
      if (authUserInfo.perfil != 'NEGOCIADOR') return false;
    }

    // acesso para o negociador poder visualizar a taxa de contrato
    if (codigo == 'acesso_taxa_contrato') {
      if (authUserInfo.perfil == 'NEGOCIADOR')
        return authUserInfo.conf_neg_ativa_taxa;
      if (authUserInfo.perfil != 'NEGOCIADOR')
        return authUserInfo.perm_taxa_contrato;
    }

    if (authUserInfo.perfil != 'NEGOCIADOR') {
      if (codigo == 'cob_boletos') return authUserInfo.bol_v;
      if (codigo == 'cob_pix') return authUserInfo.pix_v;
      if (codigo == 'cob_historicos') return authUserInfo.rel_hist_oco;
      if (codigo == 'cob_acordos_lib') return authUserInfo.acordos_lib;
      if (codigo == 'cob_recident') return authUserInfo.receb_bloq;
      if (codigo == 'instrucao_online') return authUserInfo.dev_aciona;
      if (codigo == 'cob_modelo_oco') return authUserInfo.cob_modelo_oco;
      if (codigo == 'cob_propostas') return authUserInfo.cob_propostas;
    } else {
      if (codigo == 'cob_boletos') return true;
      if (codigo == 'cob_pix') return true;
      if (codigo == 'cob_historicos') return true;
      if (codigo == 'cob_acordos_lib') return true;
      if (codigo == 'cob_recident') return true;
      if (codigo == 'instrucao_online') return true;
      if (codigo == 'cob_modelo_oco') return true;
      if (codigo == 'cob_propostas') return true;
    }

    if (
      (codigo == 'cob_acordos_lib' ||
        codigo == 'cob_agenda_ret' ||
        codigo == 'cob_acordos_prog' ||
        codigo == 'cob_gravacoes_chamadas' ||
        codigo == 'cob_comissao' ||
        codigo == 'cob_acionar' ||
        codigo == 'fin_bol_vis' ||
        codigo == 'rel_02' ||
        codigo == 'fin_cheq_vis') &&
      authUserInfo.perfil == 'NEGOCIADOR'
    )
      return true;

    if (codigo == 'fin_rec_bloq_ident') return true;

    //* ************************************************************************ COBRANCA *******************/

    if (codigo == 'cob_agenda_ret') return authUserInfo.agenda;
    if (codigo == 'cob_acordos_prog') return authUserInfo.acordos;
    if (codigo == 'cob_gravacoes_chamadas')
      return authUserInfo.gravacao_chamada;
    if (codigo == 'cob_comissao') return authUserInfo.comissao;
    if (codigo == 'cob_acionar') return authUserInfo.dev_aciona;
    if (codigo == 'cob_acordos_lib') return authUserInfo.acordos_lib;

    // ***************************************************************** DAQUI PRA BAIXO NEGOCIADOR NÃO TEM ACESSO
    if (authUserInfo.perfil == 'NEGOCIADOR') return false;

    //* ************************************************************************ CADASTRO *******************/
    if (codigo == 'cad') {
      if (
        authUserInfo.cre_v == true ||
        authUserInfo.cre_v == true ||
        authUserInfo.grp_cre == true ||
        authUserInfo.dev_v == true ||
        authUserInfo.dev_tit == true ||
        authUserInfo.colab_v == true ||
        authUserInfo.grp_colab == true ||
        authUserInfo.bancos == true ||
        authUserInfo.contas_banc == true ||
        authUserInfo.centro_custo == true ||
        authUserInfo.plano_conta == true ||
        authUserInfo.fases == true ||
        authUserInfo.segmentos == true ||
        authUserInfo.modal_contrato == true ||
        authUserInfo.comissao_escalonada == true
      )
        return true;
    }

    if (codigo == 'cad_colaborador_v') return authUserInfo.colab_v;
    if (codigo == 'cad_colaborador_c') return authUserInfo.colab_c;
    if (codigo == 'cad_colaborador_a') return authUserInfo.colab_a;
    if (codigo == 'cad_colaborador_e') return authUserInfo.colab_e;
    if (codigo == 'cad_colaborador_grupo') return authUserInfo.grp_colab;

    if (codigo == 'cad_devedor_v') return authUserInfo.dev_v;
    if (codigo == 'fin_bol_vis') return authUserInfo.bol_v;
    if (codigo == 'fin_bol_imp') return authUserInfo.bol_i;
    if (codigo == 'rel_02') return authUserInfo.rel_hist_oco;

    if (authUserInfo.unificar_grupo) return false;

    //* ************************************************************************ PLANOS ****************** */
    if (codigo == 'rel_personalizados') {
      if (
        authUserInfo.plano_empresa == 'PRO' ||
        authUserInfo.plano_empresa == 'BUSINESS'
      )
        return authUserInfo.rel_personalizados;
      return false;
    }

    if (codigo == 'metas') {
      if (
        authUserInfo.plano_empresa == 'PRO' ||
        authUserInfo.plano_empresa == 'BUSINESS'
      )
        return authUserInfo.dash;
      return false;
    }

    // if (
    //   codigo == 'fila_acionamento' &&
    //   (authUserInfo.plano_empresa == 'STARTUP+' ||
    //     authUserInfo.plano_empresa == 'PRO' ||
    //     authUserInfo.plano_empresa == 'BUSINESS')
    // )
    //   return true;

    if (codigo == 'back_fila_acionamento') {
      if (
        authUserInfo.plano_empresa == 'STARTUP+' ||
        authUserInfo.plano_empresa == 'PRO' ||
        authUserInfo.plano_empresa == 'BUSINESS'
      )
        return authUserInfo.fila_acionamento;
      return false;
    }

    if (codigo == 'automacoes') {
      if (
        authUserInfo.plano_empresa == 'STARTUP+' ||
        authUserInfo.plano_empresa == 'PRO' ||
        authUserInfo.plano_empresa == 'BUSINESS'
      )
        return true;
      return false;
    }

    if (codigo == 'back_automacoes') {
      if (authUserInfo.plano_empresa == 'BUSINESS')
        return authUserInfo.back_automacoes;
      return false;
    }

    if (codigo == 'back_discador') {
      if (
        authUserInfo.plano_empresa == 'STARTUP+' ||
        authUserInfo.plano_empresa == 'PRO' ||
        authUserInfo.plano_empresa == 'BUSINESS'
      )
        return authUserInfo.discador;
      return false;
    }

    if (
      codigo == 'bi' &&
      (authUserInfo.plano_empresa == 'PRO' ||
        authUserInfo.plano_empresa == 'BUSINESS')
    )
      return true;

    if (codigo == 'back_portal_neg') {
      if (
        authUserInfo.plano_empresa == 'STARTUP+' ||
        authUserInfo.plano_empresa == 'PRO' ||
        authUserInfo.plano_empresa == 'BUSINESS'
      )
        return authUserInfo.portal_neg;
      return false;
    }

    if (codigo == 'back_agente_virtual') return authUserInfo.agente_virtual;
    /*
    if (codigo == 'back_agente_virtual') {
      if (
        authUserInfo.plano_empresa == 'STARTUP+' ||
        authUserInfo.plano_empresa == 'PRO' ||
        authUserInfo.plano_empresa == 'BUSINESS'
      )
        return authUserInfo.agente_virtual;
    }
    */

    if (codigo == 'back_negativacao') {
      if (
        authUserInfo.plano_empresa == 'PRO' ||
        authUserInfo.plano_empresa == 'BUSINESS'
      )
        return authUserInfo.bol_conf;
      return false;
    }

    if (codigo == 'tab_inclusao_negativacao') {
      if (
        authUserInfo.plano_empresa == 'PRO' ||
        authUserInfo.plano_empresa == 'BUSINESS'
      )
        return authUserInfo.inclusao_negativacao;
      return false;
    }

    if (codigo == 'tab_retirada_negativacao') {
      if (
        authUserInfo.plano_empresa == 'PRO' ||
        authUserInfo.plano_empresa == 'BUSINESS'
      )
        return authUserInfo.retirada_negativacao;
      return false;
    }

    if (codigo === 'fin_alega_pag') {
      if (
        authUserInfo.plano_empresa == 'PRO' ||
        authUserInfo.plano_empresa == 'BUSINESS'
      )
        return authUserInfo.alega_pag;
      return false;
    }

    if (codigo == 'funcionalidade_pro') {
      if (
        authUserInfo.plano_empresa == 'STARTUP' ||
        authUserInfo.plano_empresa == 'STARTUP+'
      )
        return true;
      return false;
    }

    //* ************************************************************************ CADASTRO *******************/
    if (codigo == 'cad_credor_v') return authUserInfo.cre_v;
    if (codigo == 'cad_credor_c') return authUserInfo.cre_c;
    if (codigo == 'cad_credor_a') return authUserInfo.cre_a;
    if (codigo == 'cad_credor_e') return authUserInfo.cre_e;
    if (codigo == 'cad_credor_grupo') return authUserInfo.grp_cre;
    if (codigo == 'cad_credor_v_a')
      return authUserInfo.cre_v || authUserInfo.cre_a;

    if (codigo == 'cad_devedor_v') return authUserInfo.dev_v;
    if (codigo == 'cad_devedor_c') return authUserInfo.dev_c;
    if (codigo == 'cad_devedor_a') return authUserInfo.dev_a;
    if (codigo == 'cad_devedor_e') return authUserInfo.dev_e;
    if (codigo == 'cad_devedor_del_oco') return authUserInfo.dev_del_oco;

    if (codigo == 'cad_titulo_v') return authUserInfo.dev_tit;
    if (codigo == 'cad_titulo_c') return authUserInfo.dev_tit_c;
    if (codigo == 'cad_titulo_a') return authUserInfo.dev_tit_a;
    if (codigo == 'cad_titulo_e') return authUserInfo.dev_tit_e;
    if (codigo == 'cad_titulo_reabrir_quitado')
      return authUserInfo.reabrir_tit_quit;

    if (codigo == 'cad_fornecedor_v') return authUserInfo.forne_v;
    if (codigo == 'cad_fornecedor_c') return authUserInfo.forne_c;
    if (codigo == 'cad_fornecedor_a') return authUserInfo.forne_a;
    if (codigo == 'cad_fornecedor_e') return authUserInfo.forne_e;

    if (codigo == 'tab_bancos') return authUserInfo.bancos;
    if (codigo == 'tab_contas') return authUserInfo.contas_banc;
    if (codigo == 'tab_custo') return authUserInfo.centro_custo;
    if (codigo == 'tab_plano_conta') return authUserInfo.plano_conta;
    if (codigo == 'tab_forma_pagto') return authUserInfo.forma_pagto;
    if (codigo == 'tab_empresas') return authUserInfo.empresas;

    if (codigo == 'tab_classifica_acordo')
      return authUserInfo.classificacao_acordo;
    if (codigo == 'tab_categoria_instrucao') return authUserInfo.categoria_instrucao;
    if (codigo == 'tab_fases') return authUserInfo.fases;
    if (codigo == 'tab_tags_marcacoes') return authUserInfo.tags_marcacoes;
    if (codigo == 'tab_categoria_cred') return authUserInfo.categoria_cred;
    if (codigo == 'tab_segmento_cred') return authUserInfo.segmentos_cred;
    if (codigo == 'tab_segmento_dev') return authUserInfo.segmentos_dev;
    if (codigo == 'tab_especie_titulo') return authUserInfo.especie_titulo;
    if (codigo == 'tabelas_discador') return authUserInfo.tabelas_discador;

    if (codigo == 'tab_param_cob') return authUserInfo.param_cob;
    if (codigo == 'tab_param_car') return authUserInfo.param_car;
    if (codigo == 'tab_camp_desc') return authUserInfo.camp_desc;
    if (codigo == 'tab_modal_contr') return authUserInfo.modal_contrato;
    if (codigo == 'tab_comissao_escalonada')
      return authUserInfo.comissao_escalonada;
    if (codigo == 'tab_modelos_pc') return authUserInfo.modelos_pc;

    //* ************************************************************************ FINANCEIRO *******************/
    if (codigo == 'fin') {
      if (
        authUserInfo.receb_bloq == true ||
        authUserInfo.receb_v == true ||
        authUserInfo.baixas_v == true ||
        authUserInfo.prest_v == true ||
        authUserInfo.bol_v == true ||
        authUserInfo.che_v == true ||
        authUserInfo.rec_v == true ||
        authUserInfo.pag_v == true ||
        authUserInfo.mov_v == true ||
        authUserInfo.pix_v == true ||
        authUserInfo.fat_v == true
      )
        return true;
    }

    if (codigo == 'fin_crec_vis') return authUserInfo.rec_v;
    if (codigo == 'fin_crec_cad') return authUserInfo.rec_c;
    if (codigo == 'fin_crec_alt') return authUserInfo.rec_a;
    if (codigo == 'fin_crec_del') return authUserInfo.rec_e;
    if (codigo == 'fin_crec_quit') return authUserInfo.rec_q;

    if (codigo == 'fin_cpag_vis') return authUserInfo.pag_v;
    if (codigo == 'fin_cpag_cad') return authUserInfo.pag_c;
    if (codigo == 'fin_cpag_alt') return authUserInfo.pag_a;
    if (codigo == 'fin_cpag_del') return authUserInfo.pag_e;
    if (codigo == 'fin_cpag_quit') return authUserInfo.pag_q;

    if (codigo == 'fin_movc_vis') return authUserInfo.mov_v;
    if (codigo == 'fin_movc_cad') return authUserInfo.mov_c;
    if (codigo == 'fin_movc_alt') return authUserInfo.mov_a;
    if (codigo == 'fin_movc_del') return authUserInfo.mov_e;

    if (codigo == 'fin_rec_vis') return authUserInfo.receb_v;
    if (codigo == 'fin_rec_cad') return authUserInfo.receb_c;
    if (codigo == 'fin_rec_alt') return authUserInfo.receb_a;
    if (codigo == 'fin_rec_del') return authUserInfo.receb_e;
    if (codigo == 'fin_rec_baixar') return authUserInfo.receb_bai;
    if (codigo == 'fin_rec_bloq_vis') return authUserInfo.receb_bloq;
    if (codigo == 'fin_rec_recibo') return authUserInfo.receb_recibo;

    if (codigo == 'fin_baixas_vis') return authUserInfo.baixas_v;
    if (codigo == 'fin_baixas_alt_neg') return authUserInfo.alt_neg_baixa;

    if (codigo == 'fin_pc_vis') return authUserInfo.prest_v;
    if (codigo == 'fin_pc_cad') return authUserInfo.prest_c;
    if (codigo == 'fin_pc_alt') return authUserInfo.prest_a;
    if (codigo == 'fin_pc_del') return authUserInfo.prest_e;
    if (codigo == 'fin_pc_est') return authUserInfo.prest_est;
    if (codigo == 'fin_pc_audit') return authUserInfo.prest_conf;
    if (codigo == 'fin_pc_imprimir') return authUserInfo.prest_i;

    if (codigo == 'fin_rep_vis') return authUserInfo.rep_v;
    if (codigo == 'fin_rep_repassar') return authUserInfo.rep_r;
    if (codigo == 'fin_rep_baixar') return authUserInfo.rep_bai;

    if (codigo == 'fin_cheq_vis') return authUserInfo.che_v;
    if (codigo == 'fin_cheq_cad') return authUserInfo.che_c;
    if (codigo == 'fin_cheq_alt') return authUserInfo.che_a;
    if (codigo == 'fin_cheq_del') return authUserInfo.che_e;
    if (codigo == 'fin_cheq_est') return authUserInfo.che_est;
    if (codigo == 'fin_cheq_comp') return authUserInfo.che_comp;

    if (codigo == 'fin_bol_vis') return authUserInfo.bol_v;
    if (codigo == 'fin_bol_cad') return authUserInfo.bol_c;
    if (codigo == 'fin_bol_alt') return authUserInfo.bol_a;
    if (codigo == 'fin_bol_del') return authUserInfo.bol_e;
    if (codigo == 'fin_bol_liq') return authUserInfo.bol_liq;
    if (codigo == 'fin_bol_can') return authUserInfo.bol_can;
    if (codigo == 'fin_bol_rem') return authUserInfo.bol_rem;
    if (codigo == 'fin_bol_ret') return authUserInfo.bol_ret;
    if (codigo == 'fin_bol_imp') return authUserInfo.bol_i;

    if (codigo == 'fin_pix_vis')
      return authUserInfo.ativa_pix_cob && authUserInfo.pix_v;
    if (codigo == 'fin_fat_vis') return authUserInfo.fat_v;

    if (codigo == 'fin_nf') return authUserInfo.fin_nf;

    //* ************************************************************************ BACKOFFICE *******************/

    if (codigo == 'back') {
      if (
        authUserInfo.ctrl_desk == true ||
        authUserInfo.hig_dev == true ||
        authUserInfo.mtit == true ||
        authUserInfo.camp_email == true ||
        authUserInfo.camp_sms == true ||
        authUserInfo.camp_bol == true ||
        authUserInfo.acordos_lib == true ||
        authUserInfo.devol == true ||
        authUserInfo.hist_acessos == true
      )
        return true;
    }

    if (codigo == 'back_regua_cobranca')
      return authUserInfo.back_regua_cobranca;
    if (codigo == 'back_portal_credor') return authUserInfo.back_portal_credor;
    if (codigo == 'back_baixas_titulos_planilha')
      return authUserInfo.baixa_titulo_planilha;
    if (codigo == 'back_baixas_titulos_lote')
      return authUserInfo.baixa_titulo_lote;
    if (codigo == 'back_migrar_processos') return authUserInfo.migrar_processos;
    if (codigo == 'back_encerrar_processo_sem_saldo')
      return authUserInfo.encerra_proc_sem_saldo;
    if (codigo == 'back_distribuicao_processo_planilha')
      return authUserInfo.distribuicao_planilha;
    if (codigo == 'back_alterar_neg_acordo_planilha')
      return authUserInfo.alterar_neg_acordo;
    if (codigo == 'back_atualizar_status_planilha')
      return authUserInfo.atualiza_status_proc_planilha;
    if (codigo == 'back_devedor_titulo_excluido')
      return authUserInfo.devedor_titulo_excluido;
    if (codigo == 'back_atualiza_fones') return authUserInfo.atualiza_fones;
    if (codigo == 'back_higienizacao_dev') return authUserInfo.hig_dev;
    if (codigo == 'back_importacoes_tit') return authUserInfo.imp_titulos;
    if (codigo == 'back_importacao_oco') return authUserInfo.imp_oco;
    if (codigo == 'back_manutencao_tit') return authUserInfo.mtit;
    if (codigo == 'back_acoes_email') return authUserInfo.camp_email;
    if (codigo == 'back_acoes_sms') return authUserInfo.camp_sms;
    if (codigo == 'back_acoes_boletos') return authUserInfo.camp_bol;
    if (codigo == 'back_liberacao_acordo') return authUserInfo.acordos_lib;
    if (codigo == 'back_devolucao') return authUserInfo.devol;
    if (codigo == 'back_distribuicao') return authUserInfo.distr_tit;
    if (codigo == 'back_hist_acessos') return authUserInfo.hist_acessos;
    if (codigo == 'back_documentos_digitais') return authUserInfo.doc_digital;
    if (codigo == 'back_titulos_a_liberar')
      return authUserInfo.titulos_a_liberar;
    if (codigo == 'back_controldesk') return authUserInfo.ctrl_desk;

    //* ************************************************************************ DIVERSOS *******************/
    if (codigo == 'acordo_reabrir') return authUserInfo.receb_bai;
    if (codigo == 'acordo_rec_cad') return authUserInfo.receb_c;

    if (codigo == 'comercial') return authUserInfo.comercial;

    //* ************************************************************************ RELATORIOS *******************/
    if (codigo == 'rel') {
      if (
        authUserInfo.rel_cons_geral == true ||
        authUserInfo.rel_hist_oco == true ||
        authUserInfo.rel_tit == true ||
        authUserInfo.rel_dev == true ||
        authUserInfo.rel_cons_com == true ||
        authUserInfo.rel_fin == true ||
        authUserInfo.rel_ger == true ||
        authUserInfo.remret == true ||
        authUserInfo.rel_doc == true
      )
        return true;
    }

    if (codigo == 'rel_01') return authUserInfo.rel_cons_geral;
    if (codigo == 'rel_02') return authUserInfo.rel_hist_oco;
    if (codigo == 'rel_03') return authUserInfo.rel_tit;
    // if (codigo == 'rel_04') return authUserInfo.rel_cre;
    if (codigo == 'rel_05') return authUserInfo.rel_dev;
    if (codigo == 'rel_06') return authUserInfo.rel_neg;
    if (codigo == 'rel_07') return authUserInfo.rel_cons_com;
    if (codigo == 'rel_08') return authUserInfo.rel_fin;
    if (codigo == 'rel_09') return authUserInfo.rel_ger;
    if (codigo == 'rel_remessa') return authUserInfo.remret;
    if (codigo == 'rel_gerador') return authUserInfo.rel_doc;
    if (codigo == 'rel_pdf') return authUserInfo.rel_pdf;

    //* ************************************************************************ CONFIGURAÇÕES *******************/

    if (codigo == 'conf') {
      if (
        authUserInfo.config == true ||
        authUserInfo.layout_imp == true ||
        authUserInfo.layout_hig == true ||
        authUserInfo.status_proc == true ||
        authUserInfo.status_hist == true ||
        authUserInfo.contas_email == true ||
        authUserInfo.nivel_acesso == true ||
        authUserInfo.ip_liberados == true ||
        authUserInfo.horario_acesso == true ||
        authUserInfo.politica_senha == true
      )
        return true;
    }

    if (codigo == 'conf_gerais') return authUserInfo.config;
    if (codigo == 'conf_layouts_imp') return authUserInfo.layout_imp;
    if (codigo == 'conf_layouts_hig') return authUserInfo.layout_hig;
    if (codigo == 'conf_status_proc') return authUserInfo.status_proc;
    if (codigo == 'conf_status_hist') return authUserInfo.status_hist;
    if (codigo == 'conf_ip_liberado') return authUserInfo.ip_liberados;
    if (codigo == 'conf_horario_acesso') return authUserInfo.horario_acesso;
    if (codigo == 'conf_politica_de_senhas') return authUserInfo.politica_senha;

    if (codigo == 'conf_classifica_tit') {
      if (authUserInfo.status_hist) return true;
      return false;
    }

    if (codigo == 'conf_emails') return authUserInfo.contas_email;
    if (codigo == 'conf_niveis') return authUserInfo.nivel_acesso;
  }

  return false;
}
